import React, { useContext } from "react";
import AgGrid from "components/MDGrid";
import { ReportsFilter } from ".";
import { AgGridReact } from "ag-grid-react";

//?NOTE - Tab value against report name
/**
 * 0 => Sales Report
 * 1 => Return / Refund Report
 * 2 => Summary Report
 * 3 => Footfall Report
 * 4 => Stock Report
 * 5 => Event Report
 * 6 => GMV Report
 */
//!ACTION:
/**
 * TODO: Refactor Sales and Return and Refund data as per there tab instead of fetching it in one go in sales report
 */
function ReportTables({ loading, rowData, columnDefs }) {
  const {
    selectedBrand,
    selectedStore,
    startDate,
    endDate,
    tabValue,
    getStockReport,
    getSalesReport,
    key,

  } = useContext(ReportsFilter);

  const fetchFn = () => {
    if (tabValue === 0) {
      return getSalesReport;
    } else if (tabValue === 1) {
      return getSalesReport; // it is compatible for both sales and return report
    } else if (tabValue === 4) {
      return getStockReport;
    }
  };
  // Determine the resultsParser dynamically based on the tabValue
  const resultsParser = (data) => {
    switch (tabValue) {
      case 0:
      case 1:
        return {
          results: data?.getSalesReports?.results || [],
          total: data?.getSalesReports?.total || 0,
        };
      case 4:
        return {
          results: data?.getStockReports?.results || [],
          total: data?.getStockReports?.total || 0,
        };
      default:
        return {
          results: [],
          total: 0,
        };
    }
  };

  return (
    <div className={"ag-theme-quartz-dark"} style={{ height: 500 }}>
      {![5].includes(tabValue) ? (
        <div
          className="ag-theme-alpine"
          style={{ height: "85vh", width: "100%", marginTop: "20px" }}
        >
          <AgGridReact
            loading={loading}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={{
              sortable: true,
              filter: "agSetColumnFilter",
              resizable: true,
              enableRowGroup: true,
              enablePivot: true,
              enableValue: true,
              floatingFilter: true,
            }}
            groupRowRenderer={"agGroupCellRenderer"}
            groupRowRendererParams={{
              suppressCount:true,
              innerRenderer: (params) => {
                const isStoreGroup = params.node?.level === 0;
                const isDateGroup = params.node?.level === 1;
                if (isStoreGroup) {
                  const storeSum = params.node.childrenAfterAggFilter.reduce((sum, childNode) => {
                    console.log(childNode)
                    const groupSum = childNode?.allLeafChildren?.[0]?.data?.count || 0;
                    return sum + groupSum;
                  }, 0);
                  return (
                    <span>
                      <span style={{width:"100px", display:"inline-block"}}>
                        {params.value}
                      </span>
                      <span style={{ marginLeft: '20px' }}> - Total Visitors: {storeSum}</span>
                    </span>
                  );
                } else if (isDateGroup) {
                  const groupSum = params?.node?.childrenAfterAggFilter?.[0]?.data?.count || 0;
                  return (
                    <span>
                      {params.value}
                      <span style={{ marginLeft: '20px' }}> - Total Visitors: {groupSum}</span>
                    </span>
                  );
                } else {
                  // Default rendering for other groups if any (this is optional)
                  return <span>{params.value}</span>;
                }
              }}}
            groupDisplayType={"groupRows"}
            rowGroupPanelShow={"always"} // Show row group panel
            enableCharts={true} // Enable chart visualizations
            enableRangeSelection={true} // Enable range selection for charts
            animateRows={true} // Smooth transitions
            sideBar={{ toolPanels: ["columns", "filters"] }}
          />
        </div>
      ) : (
        <AgGrid
          key={key}
          readMethod={{
            fetchFn: fetchFn(),
            inputParser: ({ startRow, endRow, filter, sortModel }) => {
              return {
                take: endRow - startRow,
                skip: startRow,
                startDate: startDate || null,
                endDate: endDate || null,
                searchCols: Object.keys(filter) || [],
                searchBy: Object.values(filter) || [],
                orderType:
                  tabValue === 0 ? "PURCHASE" : tabValue === 1 ? "RETURN" : "",
                filter: {
                  brandId: selectedBrand || null,
                  storeId: selectedStore || null,
                },
              };
            },
            resultsParser: ({ data }) => resultsParser(data),
          }}
          columns={columnDefs}
          defaultColDefProp={{
            filter: "agTextColumnFilter",
            editable: false,
            filterParams: {
              filterOptions: ["contains"],
              defaultOption: "contains",
              maxNumConditions: 1,
            },
          }}
        />
      )}
    </div>
  );
}

export default ReportTables;
